import React, { useCallback, useMemo } from "react"
import { cn } from "@daybridge/cn"
import { motion } from "framer-motion"
import { useTranslations } from "next-intl"
import { EventTimed } from "../../(calendar)/_components/items/types/event/EventTimed"
import { IconId, ItemType } from "../../../../../data/_gen/types"
import { useNow } from "../../../../../lib/useNow"
import { BlockTimed } from "../../(calendar)/_components/items/types/block/BlockTimed"
import { TaskTimed } from "../../(calendar)/_components/items/types/task/TaskTimed"
import { MarkerTimed } from "../../(calendar)/_components/items/types/marker/MarkerTimed"
import { TravelTimed } from "../../(calendar)/_components/items/types/travel/TravelTimed"
import { styleForHue } from "../../../../../lib/styleForHue"

export const classOverrides = cn(
  "[&>*]:text-md",
  "[&_.item-times]:text-base",
  "[&_.item-times]:mt-0",
  "[&_.item-icon]:w-6 [&_.item-icon]:h-6",
  "rounded-xl [&_.item-ring]:rounded-xl",
  "cursor-default",
)

export const ThemeIllustration = () => {
  const now = useNow()
  const t = useTranslations("signup")

  const containerVariants = useMemo(() => {
    return {
      initial: {},
      animate: {
        transition: {
          staggerChildren: 0.1,
          delayChildren: 0.5,
        },
      },
      exit: {
        transition: {
          when: "afterChildren",
          staggerChildren: 0.05,
        },
      },
    }
  }, [])

  const itemVariants = useCallback((index: number) => {
    return {
      initial: {
        opacity: 0,
        x: index % 2 === 0 ? -20 : 20,
        y: index % 2 === 0 ? 200 : -200,
        rotate: 6,
      },
      animate: {
        opacity: 1,
        x: 0,
        y: 0,
        rotate: 6,
        transition: { type: "spring", damping: 25, stiffness: 150 },
      },
      exit: {
        opacity: 0,
        x: index % 2 === 0 ? 20 : -20,
        y: index % 2 === 0 ? -200 : 200,
        rotate: 6,
        transition: { type: "spring", damping: 25, stiffness: 200 },
      },
    }
  }, [])

  return (
    <div className="absolute w-[1500px] h-full flex items-center justify-center">
      <motion.div
        variants={containerVariants}
        initial="initial"
        animate="animate"
        exit="exit"
        className={cn(
          "absolute inset-0",
          "flex flex-row items-center justify-center",
          "font-sans",
        )}
      >
        <motion.div
          initial={{ opacity: 0, rotate: 6, y: -100, scale: 0.5 }}
          animate={{ opacity: 1, rotate: 6, y: 0, scale: 1 }}
          transition={{ type: "spring", damping: 10, stiffness: 30 }}
          exit={{
            opacity: 0,
            rotate: 6,
            y: 100,
            transition: { ease: "easeInOut", duration: 0.5, delay: 0 },
          }}
          className={cn(
            "absolute",
            "w-[200%] h-[300%]",
            "origin-center",
            "filter drop-shadow-[0_0_2px_rgba(255,255,255,1)] dark:drop-shadow-[0_0_2px_rgba(0,0,0,1)]",
          )}
        >
          <div
            className={cn(
              "absolute inset-0",
              "bg-gradient-to-r from-tint-light to-tint-heavy",
              "flex items-center justify-center",
            )}
            style={{
              maskSize: `250px 150px`,
              maskImage: `linear-gradient(to right, white 1px, transparent 1px), linear-gradient(to top, white 1px, transparent 1px)`,
              maskPosition: "center center",
            }}
          />
        </motion.div>

        <motion.div
          variants={itemVariants(0)}
          className={cn("flex flex-col", "relative", "mr-2")}
          style={{
            width: 244,
            height: 320,
          }}
        >
          <div className={cn("@container/placement", "h-16")}>
            <BlockTimed
              item={{
                id: "event",
                type: ItemType.Block,
                start: now.set({ hour: 8, minute: 0 }),
                end: now.set({ hour: 8, minute: 30 }),
                title: t("passport_control"),
                cancelled: false,
                deleted: false,
                icon: IconId.Immigration,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={cn(
                classOverrides,
                "rounded-none [&_.item-ring]:rounded-none",
              )}
              style={{ ...styleForHue(0) }}
            />
          </div>
          <div className={cn("@container/placement", "flex-1")}>
            <TravelTimed
              item={{
                id: "event",
                type: ItemType.Travel,
                start: now.set({ hour: 8, minute: 30 }),
                end: now.set({ hour: 11, minute: 0 }),
                title: t("train_to_london"),
                cancelled: false,
                deleted: false,
                icon: IconId.Train,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={classOverrides}
              style={{ ...styleForHue(20) }}
            />
          </div>
          <div className={cn("@container/placement", "h-8")}>
            <TaskTimed
              item={{
                id: "event",
                type: ItemType.Block,
                start: now.set({ hour: 11, minute: 0 }),
                end: now.set({ hour: 11, minute: 30 }),
                title: t("hotel_check_in"),
                cancelled: false,
                deleted: false,
                icon: IconId.Suitcase,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={classOverrides}
              style={{ ...styleForHue(40) }}
            />
          </div>
        </motion.div>
        <motion.div
          variants={itemVariants(1)}
          className={cn(
            "flex flex-col",
            "@container/placement",
            "relative",
            "mr-2",
          )}
          style={{
            width: 244,
            height: 120,
          }}
        >
          <EventTimed
            item={{
              id: "event",
              type: ItemType.Event,
              start: now.set({ hour: 12, minute: 0 }),
              end: now.set({ hour: 13, minute: 30 }),
              title: t("lunch_with_fiona"),
              cancelled: false,
              deleted: false,
              icon: IconId.KnifeFork,
            }}
            cancelledText=""
            declinedText=""
            noTitleText=""
            className={classOverrides}
            style={{ ...styleForHue(60) }}
          />
        </motion.div>
        <motion.div
          variants={itemVariants(2)}
          className={cn(
            "flex flex-col",
            "@container/placement",
            "relative",
            "mr-2",
          )}
          style={{
            width: 244,
            height: 200,
          }}
        >
          <BlockTimed
            key="block"
            item={{
              id: "event",
              type: ItemType.Event,
              start: now.set({ hour: 19, minute: 0 }),
              end: now.set({ hour: 22, minute: 0 }),
              title: t("studying_for_CS101"),
              cancelled: false,
              deleted: false,
              icon: IconId.Book,
            }}
            cancelledText=""
            declinedText=""
            noTitleText=""
            className={cn(
              classOverrides,
              "rounded-none [&_.item-ring]:rounded-none",
            )}
            style={{ ...styleForHue(180) }}
          />
        </motion.div>
        <motion.div
          variants={itemVariants(3)}
          className={cn("flex flex-col", "relative", "mr-2")}
          style={{
            width: 244,
            height: 200,
          }}
        >
          <div className={cn("@container/placement", "flex-1")}>
            <TaskTimed
              item={{
                id: "event",
                type: ItemType.Event,
                start: now.set({ hour: 14, minute: 0 }),
                end: now.set({ hour: 15, minute: 0 }),
                title: t("finish_quarterly_report"),
                cancelled: false,
                deleted: false,
                icon: IconId.WavingHand,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={classOverrides}
              style={{ ...styleForHue(200) }}
            />
          </div>
          <div className={cn("@container/placement", "h-8")}>
            <TaskTimed
              item={{
                id: "event",
                type: ItemType.Event,
                start: now.set({ hour: 15, minute: 0 }),
                end: now.set({ hour: 15, minute: 20 }),
                title: t("send_sulleys_birthday_card"),
                cancelled: false,
                deleted: false,
                icon: IconId.WavingHand,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={classOverrides}
              style={{ ...styleForHue(240) }}
            />
          </div>
          <div className={cn("@container/placement", "flex-1")}>
            <TaskTimed
              item={{
                id: "event",
                type: ItemType.Event,
                start: now.set({ hour: 15, minute: 20 }),
                end: now.set({ hour: 16, minute: 30 }),
                title: t("pack_for_new_york"),
                cancelled: false,
                deleted: false,
                icon: IconId.WavingHand,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={classOverrides}
              style={{ ...styleForHue(260) }}
            />
          </div>
        </motion.div>
        <motion.div
          variants={itemVariants(4)}
          key="travel"
          className={cn(
            "flex flex-col",
            "@container/placement",
            "relative",
            "mr-1",
          )}
          style={{
            width: 244,
            height: 300,
          }}
        >
          <MarkerTimed
            item={{
              id: "marker",
              type: ItemType.Marker,
              start: now.set({ hour: 9, minute: 0 }),
              end: now.set({ hour: 12, minute: 0 }),
              title: t("parcel_delivery"),
              cancelled: false,
              deleted: false,
              icon: IconId.Box,
            }}
            cancelledText=""
            declinedText=""
            noTitleText=""
            className={classOverrides}
            style={{ ...styleForHue(320) }}
          />
          <div className="@container/placement absolute left-1 top-16 bottom-20 right-0">
            <EventTimed
              item={{
                id: "event",
                type: ItemType.Event,
                start: now.set({ hour: 10, minute: 0 }),
                end: now.set({ hour: 11, minute: 30 }),
                title: t("gym_session"),
                cancelled: false,
                deleted: false,
                icon: IconId.Gym,
              }}
              cancelledText=""
              declinedText=""
              noTitleText=""
              className={classOverrides}
              style={{ ...styleForHue(350) }}
            />
          </div>
        </motion.div>
      </motion.div>
    </div>
  )
}
