import React from "react"
import { Combobox, Popover, PopoverProps } from "@daybridge/components"
import { cn } from "@daybridge/cn"
import { useLanguageMenu } from "./language-menu"

const LanguagePickerFn = React.forwardRef(
  (
    props: Omit<PopoverProps, "content"> &
      Omit<LanguageComboboxProps, "onComplete">,
    ref: React.ForwardedRef<HTMLButtonElement>,
  ) => {
    const { children, className, ...rest } = props
    const [open, setOpen] = React.useState(false)

    return (
      <Popover
        open={open}
        onOpenChange={setOpen}
        content={<LanguageCombobox onComplete={() => setOpen(false)} />}
        className={cn("w-80", className)}
        ref={ref}
        {...rest}
      >
        {children}
      </Popover>
    )
  },
)
LanguagePickerFn.displayName = "LanguagePicker"
export const LanguagePicker = React.memo(
  LanguagePickerFn,
) as typeof LanguagePickerFn

export type LanguageComboboxProps = {
  onComplete?: () => void
}
const LanguageComboboxFn = React.forwardRef(
  (props: LanguageComboboxProps, ref: React.ForwardedRef<HTMLDivElement>) => {
    const { onComplete } = props

    const root = useLanguageMenu()
    return <Combobox ref={ref} root={root} onComplete={onComplete} />
  },
)
LanguageComboboxFn.displayName = "LanguageCombobox"
export const LanguageCombobox = React.memo(
  LanguageComboboxFn,
) as typeof LanguageComboboxFn
